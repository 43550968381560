*{
    src: url("https://fonts.googleapis.com/css?family=Poppins");
    scroll-behavior: smooth;
    font-family: "Poppins";
}

@media (max-width: 320px){
  .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
  }
  
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    font-size: 20px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
    border-radius: 0;
  }
  

  nav li a.active{
    border-bottom:4px solid #ff7a00;
  }
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg {
  height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  text-align: center;
  justify-items: center;
}
 .btn {
  display: none;
  gap: 2%;
  padding: 6%;
}
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
  }
  
  .bloktitle{
    width: 100%;
    display: block;
  }
  
  .title{
     padding-top: 20%;
     font-size: x-large;
     color: #fff;
     font-weight: bold;
  }
  
  .subtitle{
      margin-top: -25px;
      font-size: 15px;
      color: #fff;
      font-family: "poppins , sans serif";
  }
  
  .bttn {
    border: 0;
    height: 60px;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #0F69AB;
    font-weight: bold;
    padding: 20px 60px;
    text-decoration: none;
    font-size: 16px;
    transition: 0.5s ease-in-out;
    background-color: white;
  }

  
  .bttn:hover{
    background-color:  #dfdfdf;
  }
  
  .bttn a {
    text-decoration: none;
    }
  
  .yt {
    background-color: #68b9de;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
    border: #2980b9 2px;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    font-family: "Poppins";
    gap: 5%;
  }
  
  .button {
    background-color: white;
    width: 27vw;
    height: 10vh;
    margin: 0 10px;
    padding: 20px 0;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1vw;
  }
  
  .btn-h2bisnis {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    display: block;
  }

  .md_bisnis {
    width: auto;
    margin: 0 auto;
    display: block;
  }
  
  /* Styling untuk judul h2 */
  .h2bisnis {
    text-align: left;
  }
  
  /* Styling untuk garis horizontal */
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 400px;
    position: relative;
}


.gt {
  display: grid;
  /* align-items: center; */
  grid-template-columns: repeat(3,auto);
  /* border: 1px solid; */
  margin: 0 5%;
  /* text-align: right; */
}

.subtitle1{
  text-align: justify;
  display: block;
}

.subtitle2{
  text-align: justify;
}

.pll a {
  text-decoration: none;
  font-weight: bold;
  display: block;
  position: relative;
  bottom: 35px;
  float: right;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 5vh 5vw;
}

.dekstop{
  display: none;
}

.logo-pd{
  width: 100px;
}

.content_pd{
  padding: 20px;
  font-size:small;
  text-align: justify;
}

.btn-pd{
 width: 100%;
}

.btn-primary{
  height: 70px;
  border-radius: 50px;
  width: 100%;
  border: none;
}

.btn-primary{
  height: 70px;
  border-radius: 15px;
  width: 100%;
  border: none;
}

.or p{
  text-align: center;
  font-weight: bold;

}

.store {
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: center;
    /* grid-template-columns: auto auto; */
  }

  .img-store {
    height: 35px;
  }

  .ps,
  .ap {
    cursor: pointer;
  }

.img-pd{
width: 100%;
}

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */

.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}

/* benefit */
.hr {
  width: 100%;
  color: white;
}

.col-lg-3{
  font-size: 2vh;
}

.row{
  margin-left: 0;
  margin-right: 0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: flex;
  gap: 5%;
  flex-direction: row;
  background-color: #0F69AB;
  padding: 2%;
  width: 100%;
}

.atasf .prom{
  float: left;
  flex: 0 0 60%;
  color: white;
}

.atasf .but{
  float: right;
}

/* Bagian "bawahf" menjadi dua kolom */
.bawahf {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
  background-color: #0D5487;
  color: white;
}

.bawahf .karir {
  display: flex;
  font-size: 12px;
  flex: 0 0 73%;
}

.bawahf .fotri {
  display: flex;
  font-size: 10px;

}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  padding: 5px 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}
.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}


.tenka{
 margin-top: 20px;
  /* background-color:#3498db ; */
  
}
 .basns{
  margin-bottom: -15%;
}

  
  .btn-h2bisnis {
    display: none;
  }

  .txben{
    padding: 12%;
    font-size: 12px;
    margin-bottom:5px ;
    text-align: justify;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 200%;
  }

.button{
  font-size: 0.9vh;
}


.bttn:hover{
  background-color:  #dfdfdf; 
}

.bttn a {
  text-decoration: none;
}

.contentt {
  display: block;
  /* margin-top: 0%; */
}

.bisnis-hide {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.gt{
  grid-template-columns: auto;
  display: flex; 
}

.gambar{
  grid-area: header;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.subtitle1{
  grid-area: body;
  text-align: left;
}

.pll{
  grid-area: footer;
}


}

@media (max-width: 427px) and (min-width: 321px){
  .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
  }
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    font-size: 20px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
    border-radius: 0;
  }

  .navbar li a, .navbar .navbar-brand {
    color: #1777bd !important;
  }
  
  
  .navbar li a:active::after{
    background: #ff7a00;
  }

  nav li a.active{
    border-bottom:2px solid #ff7a00;
  }
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg {
  height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  text-align: center;
  justify-items: center;
}
 .btn {
  display: none;
  gap: 2%;
  padding: 6%;
}
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
  }
  
  .bloktitle{
    width: 100%;
    display: block;
  }
  
  .title{
     padding-top: 20%;
     font-size: x-large;
     color: #fff;
     font-weight: bold;
  }
  
  .subtitle{
      margin-top: -25px;
      font-size: 15px;
      color: #fff;
      font-family: "poppins , sans serif";
  }
  
  .bttn {
    border: 0;
    height: 60px;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #0F69AB;
    font-weight: bold;
    padding: 20px 60px;
    text-decoration: none;
    font-size: 16px;
    transition: 0.5s ease-in-out;
    background-color: white;
  }
  
  .bttn:hover{
    background-color:  #dfdfdf;
  }
  
  .bttn a {
    text-decoration: none;
    }
  
  .yt {
    background-color: #68b9de;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
    border: #2980b9 2px;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    gap: 2%;
  }
  
  .button {
    height: 120px;
    font-size: 12px;
    text-wrap: auto;
    background-color: white;
    width: 120px;
    /* margin: 0 5px; */
    padding: 30px 0;
    border-radius: 20px;
    font-weight: bold;
  }
  
  .btn-h2bisnis {
    width: auto;  
    margin: 0 auto;
    display: block;
  }

  .gt{
    grid-template-areas: 
      "header"
      "body"
      "footer"
    ;
    display: grid;
    
  }
  .gambar{
    grid-area: header;
    width: 100%;
    height: 100%;
  }
  
  .subtitle1{
    grid-area: body;
    text-align: justify;
    font-size: 10px;
  }
  
  .pll{
    grid-area: footer;
  }

.subtitle2{
  text-align: justify;
}

.pll a {
  text-decoration: none;
  font-weight: bold;
  display: block; 
  align-items: right;
  float: right;
}

  .md_bisnis {
    display: none;
  }
  
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 400px;
    position: relative;
}

.btn_bis {
  display: block;
  width: 30px;
  height: 4px;
  background-color: #333;
  margin-bottom: 5px;
  border-radius: 2px;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 5vh 10px;
  padding-left: 0;
  padding-right: 0;
}

.dekstop{
  display: none;
}

.logo-pd{
  width: 100px;
}

.content_pd{

  text-align: justify;
}

.btn-pd{
 width: 100%;
 padding: 20px;
}

.btn-primary{
  height: 70px;
  border-radius: 15px;
  width: 100%;
  border: none;
}

.or p{
  text-align: center;
  font-weight: bold;

}

.store {
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  justify-content: center;
    /* grid-template-columns: auto auto; */
  }

  .img-store {
    height: 35px;
  }

  .ps,
  .ap {
    cursor: pointer;
  }

.img-pd{
width: 100%;
}

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */
.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}

/* benefit */

.hr {
  width: 100%;
  color: white;
}

.col-lg-3{
  font-size: 2vh;
}

.row{
  margin-left: 0;
  margin-right: 0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: flex;
  gap: 5%;
  flex-direction: row;
  background-color: #0F69AB;
  padding: 2%;
  width: 100%;
}

.atasf .prom{
  float: left;
  flex: 0 0 60%;
  color: white;
}

.atasf .but{
  float: right;
}

/* Bagian "bawahf" menjadi dua kolom */
.bawahf {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
  background-color: #0D5487;
  color: white;
}

.bawahf .karir {
  display: flex;
  flex: 0 0 70%;
}

.bawahf .fotri {
  display: flex;
  font-size: 12px;

}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  padding: 5px 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}

.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconf {
  /* margin-bottom: 2vh; */
  width: 20px;
  height: 20px;
  margin-right: -10px;
  margin-left: 20px;
}


.tenka{
 margin-top: 20px;
  /* background-color:#3498db ; */  
}

  .txben{
    padding: 12%;
    font-size: 12px;
    margin-bottom:5px ;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 200%;
  }
 
.contentt {
  display: block;
  /* margin-top: 0%; */
}

.bisnis-hide {
  display: block;
  text-align: center;
  margin-top: 10px;
}


}

@media (max-width: 767px) and (min-width: 428px){
  .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
  }
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    font-size: 20px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
    border-radius: 0;
  }

  .navbar li a, .navbar .navbar-brand {
    color: #1777bd !important;
  } 
  
  .navbar li a:active::after{
    background: #ff7a00;
  }

  nav li a.active{
    border-bottom:4px solid #ff7a00;
  }
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg {
  height: 500px;
  width: 100%;
  background: url("../assets/images/background2.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  justify-items: center;
}
 .btn {
  display: none;
  gap: 2%;
  padding: 6%;
}
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  }
   */
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
  }
  
  .bloktitle{
    width: 100%;
    display: block;
  }
  
  .title{
     padding-top: 20%;
     font-size: x-large;
     color: #fff;
     font-weight: bold;
  }
  
  .subtitle{
      margin-top: -25px;
      font-size: 15px;
      color: #fff;
      font-family: "poppins , sans serif";
  }
  
  .bttn {
    border: 0;
    height: 60px;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #0F69AB;
    font-weight: bold;
    padding: 20px 60px;
    text-decoration: none;
    font-size: 16px;
    transition: 0.5s ease-in-out;
    background-color: white;
  }
  
  .bttn:hover{
    background-color:  #dfdfdf;
  }
  
  .bttn a {
    text-decoration: none;
    }
  
  .yt {
    background-color: #68b9de;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    font-family: "Poppins";
    gap: 1px;
  }

  .md_bisnis {
    display: none;
  }
  
  /* Styling untuk judul h2 */
  .h2bisnis {
    text-align: left;
  }
  
  /* Styling untuk garis horizontal */
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 400px;
    position: relative;
}

.btn_bis {
  display: block;
  width: 30px;
  height: 4px;
  background-color: #333;
  margin-bottom: 5px;
  border-radius: 2px;
}

.button {
  background-color: white;
  width: 131px;
  height: 70px;
  margin: 0 5px;
  padding: 0px 0;
  border-radius: 20px;
  font-weight: bold;
  /* font-size: 6.5px; */
  font-size: 12px;
  text-wrap: auto;
}

.btn-h2bisnis {
  width: auto;
  margin: 0 auto;
  /* padding: 20px; */
  display: block;
}

.gt{
  grid-template-areas: 
    "header"
    "body"
    "footer"
  ;
  display: grid;
  
}
.gambar{
  grid-area: header;
  width: 100%;
  height: 100%;
}

.subtitle1{
  grid-area: body;
  text-align: justify;
  font-size: 10px;
}

.pll{
  grid-area: footer;
}

.subtitle2{
  text-align: justify;
}

.pll a {
  text-decoration: none;
  font-weight: bold;
  display: block;
  align-items: right;
  font-size: 10px;
  float: right;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 5vh 0px;
}

.dekstop{
  display:none;
}

.logo-pd{
  width: 75px;
}

.content_pd{
  padding: 20px;
  padding-left: 0px;
  padding-right: 0;
  font-size:small;
  text-align: justify;
}

.btn-pd {
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-primary{
  height: 70px;
  border-radius: 15px;
  width: 100%;
}

.or p{
  text-align: center;
  font-weight: bold;

}

.store {
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: center;
    /* grid-template-columns: auto auto; */
  }

.img-store {
  height: 40px;
}

  .ps,
  .ap {
    cursor: pointer;
  }

.img-pd{
width: 100%;
}

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */

.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}

.hr {
  width: 100%;
  color: white;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: grid;
  grid-template-columns: 60% 40%;
  background-color: #0F69AB;
  padding: 3%;
  width: 100%;
}

.atasf .prom{
  float: left;
  font-size: 10px;
  color: white;
}

.atasf .but{
  float: right;
}

/* Bagian "bawahf" menjadi dua kolom */
.bawahf {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
  background-color: #0D5487;
  color: white;
}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  padding: 5px 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}

.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tenka{
 margin-top: 20px;
  /* background-color:#3498db ; */
}

  .txben{
    padding: 12%;
    font-size: 12px;
    margin-bottom:5px ;
    padding-top: 4%;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 200%;
  }

.contentt {
  display: block;
  /* margin-top: 0%; */
}

/* .bisnis-hide {
  display: block;
  text-align: center;
  margin-top: 10px;
} */



}





/*----------------------------------------------------------------------*/

@media (max-width: 1024px) and (min-width:768px){
  .logo{
    width: 100%;
    vertical-align: middle;
    margin-top: -2vh;
  }
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
    border-radius: 0;
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 20px;

  }

  .navbar li a, .navbar .navbar-brand {
    color: #1777bd !important;
    font-size: 1.5vw;
    margin: 0;
    padding-top: 40px;
    
  }
  
  .navbar-nav li a:hover a:active, .navbar-nav li.active a {
    color: #f4511e !important;
    transition: 0.4s ease-in-out;
    /* background-color: #fff !important; */
  }
  
  
  nav li a.active{
    border-bottom: 4px solid #ff7a00;
  }

  /* nav li a.active{
    border-bottom:4px solid #ff7a00;
  } */
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  }
   */
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 60%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
  }
  .bg {
    margin-top: 25px;
    height: 600px;
    min-height: 400px;
    width: 100%;
    background: url("../assets/images/background2.png");
    background-size: cover;
    background-position: center;
    text-align: center;
    justify-items: center;
  }

.bttn {
  border: 0;
  border-radius: 10px;
  margin-top: 2.5vh;
  font-family: 'Poppins';
  color: #0F69AB;
  font-weight: bold;
  padding: 12px 60px;
  text-decoration: none;
  font-size: 2.5vw;
  transition: 0.5s ease-in-out;
  background-color: white;
}

.bttn:hover{
  background-color:  #dfdfdf;
}

.bttn a {
  text-decoration: none;

}
 .title{
  padding-top: 75px;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.subtitle{
  margin-top: -2vh;
   font-size: 2.5vw;
   text-align: center;
   color: #fff;
   font-family: "poppins , sans serif";
}
  
  .yt {
    background-color: #68b9de;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
    border: #2980b9 2px;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  

  .md_bisnis {
    display:none;
  }
  
  /* Styling untuk judul h2 */
  .h2bisnis {
    text-align: left;
  }
  
  /* Styling untuk garis horizontal */
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover,
  .button:focus {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 600px;
    position: relative;
}

.btn {
  display: contents;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  font-family: "Poppins";
  gap: 5%;
}

.button {
  background-color: white;
  width: 225px;
  height: 60px;
  /* margin: 0 10px; */
  padding: 20px 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 10px;
}

.btn-h2bisnis {
  width: auto;
  margin: 0 auto;
  /* padding: 20px; */
  display: block;
  /* display: contents; */
}

.gt {
  display: grid;
  grid-template-columns: 50% 45%;
  gap: 5%;

}

.subtitle1{
  text-align: justify;
  font-size: 10px;
  display: grid;
}

.subtitle2{
  text-align: justify;
}

.subtitle2{
  text-align: justify;
}

.gambar {
  padding-right: 1vw;
  width: 100%;
  height: auto;
}

.pll a {
  text-decoration: none;
  font-weight: bold;
  margin-top: 30px;
  font-size: 10px;
  float: right;
}

.dekstop{
  display: none;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 12vh 15px;
}

.logo-pd{
  width: 135px;
}

.content_pd{
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  font-size:large;
  text-align: justify;
}

.btn-pd {
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-primary{
  height: 70px;
  width: 100%;
  border-radius: 10px;
  border: none;
}

.or p{
  text-align: center;
  font-weight: bold;

}

.store {
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: center;
    /* grid-template-columns: auto auto; */
  }

  .ps,
  .ap {
    cursor: pointer;
  }

.img-pd{
  width: 100%;
}

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */

.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}

/* benefit */

.hr {
  width: 100%;
  color: white;
}

.col-lg-3 {
  width: 25%;
}

.row{
  margin-left: 0;
  margin-right: 0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: grid;
  grid-template-columns: 70% 30%;
  flex-direction: row;
  background-color: #0F69AB;
  padding: 3%;
  width: 100%;
}

.atasf .prom{
  font-size: 15px;
  color: white;
}

.atasf .but{
  float: right;
}

/* Bagian "bawahf" menjadi dua kolom */
.bawahf {
  align-items: stretch;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
  padding: 2%;
  background-color: #0D5487;
  color: white;
}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  padding: 5px 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 120%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}

.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconf {
  /* margin-bottom: 2vh; */
  width: 20px;
  height: 20px;
  margin-right: -10px;
  margin-left: 20px;
}

p{
  margin-bottom: 0px;
}

.tenka{
  margin-top: 20px;
  /* background-color:#3498db ; */
} 

  .txben{
    padding: 11%;
    padding-bottom: 9%;
    font-size: 20px;
  }

.contentt {
  display: block;
  /* margin-top: 0%; */
}

.bisnis-hide {
  display: block;
  text-align: center;
  margin-top: 10px;
}

    .rr{
      display: flex;
      font-size: 15px;
    }

    .row1{
      text-align: left;
    
    }

    .row2{
      text-align: right;
    }

    .col-md-6{
      padding: 0;
    }

/* .gt{
  grid-template-areas: 
    "header"
    "body"
    "footer"
  ;
  display: flex;
  
}
.gambar{
  grid-area: header;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.subtitle1{
  grid-area: body;
  text-align: left;
}

.pll{
  grid-area: footer;
} */


}

/*-----------------------------------------------------------------------*/

@media (max-width: 1280px) and (min-width:1025px) { 
  .logo{
    width: 100%;
    vertical-align: middle;
    margin-top: -10px;
  }




  .rawr{
    display: grid;
    grid-template-columns: 50% auto;
  }

  
  /* .md_bisnis {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    display: none;
  } */
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    font-size: 20px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
    border-radius: 0;
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 50px;
    padding-top: 20px;
  }

  .navbar li a, .navbar .navbar-brand {
    color: #1777bd !important;
    font-size: 20px;
    padding-bottom: 30px;
  }
  
  .navbar-nav li a:hover a:active, .navbar-nav li.active a {
    color: #f4511e !important;
    transition: 0.4s ease-in-out;
    /* background-color: #fff !important; */
  }

  .navbar-nav li a:hover a:active, .navbar-nav li.active a {
    color: #f4511e !important;
    transition: 0.4s ease-in-out;
    /* background-color: #fff !important; */
  }
  
  
  .navbar li a:active::after{
    background: #ff7a00;
  }

  nav li a.active{
    border-bottom:4px solid #ff7a00;
  }
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 100%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
    padding-left: 0;
  }
  .bg {
    /* margin-top: 3%; */
    height: 100%;
    width: 100%;
    background-size:cover;
    background-size: cover;
}

.bann {
  height: auto;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bgon{
  display: grid;
  grid-template-columns: 40% 60%;
}

  .bktitle{
    padding-top: 30%;
   padding-bottom: 30%;
  }
  
  .title{
     margin-left: 20;
     font-size: 2vw;
     color: #fff;
     font-weight: bold;
  }
  
  .subtitle{
      margin-top: -2vh;
      font-size: 1vw;
      color: #fff;
      font-family: "poppins , sans serif";
  }
  
  .bttn {
    border: 0;
    height: 60px;
    width: 20vw;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #0F69AB;
    font-weight: bold;
    /* padding: 12px 60px; */
    text-decoration: none;
    transition: 0.5s ease-in-out;
    background-color: white;
  }
  .bttn:hover{
    background-color:  #dfdfdf; 
  }
  .bttn a {
    text-decoration: none;
    font-size: 20px;
  }
  .yt {
    background-color: #68b9de;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
    border: #2980b9 2px;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    font-family: "Poppins";
    gap: 5%;
  }
  
  .button {
    background-color: white;
    width: 345px;
    height: 80px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 15px;
  }
  
  .btn-h2bisnis {
    width: auto;
    margin: 0 auto;
    display: block;
  }
  
  /* Styling untuk judul h2 */
  .h2bisnis {
    text-align: left;
  }

  
  
  /* Styling untuk garis horizontal */
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover,
  .button:focus {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 400px;
    position: relative;
}

.md_bisnis {
  display: none;
}

.gt {
  display: grid;
  grid-template-columns: 45% 50%;
  gap: 5%;

}

.subtitle1{
  text-align: justify;
}

.gambar {
  width: 100%;
  height: auto;
}

.pll a {
  text-decoration: none;
  font-weight: bold;
  align-items: right;
  margin-top: 50px;
  font-size: 20px;
  float: right;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 5vh 5vw;
}

.hp{
  display: none;
}

.logo-pd{
  width: 150px;
}

.content_pd{
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-pd{
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-primary{
  height: 70px;
  width: 365px;
  border-radius: 10px;
  border: none;
}

.or p{
  padding-left:165px;
  font-weight: bold;
}

.store {
  padding: 20px;
  padding-left: 0;
  cursor: pointer;
  display: flex;
  gap: 20px;
  
    /* grid-template-columns: auto auto; */
  }

  .ps,
  .ap {
    cursor: pointer;
  }

.img-pd{
  padding: 50px;
}

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */

.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}
/* benefit */


.hr {
  width: 100%;
  color: white;
}

.row{
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: flex;
  gap: 5%;
  flex-direction: row;
  background-color: #0F69AB;
  padding: 2%;
  width: 100%;
}

.atasf .prom{
  float: left;
  flex: 0 0 80%;
  color: white;
}

.atasf .but{
  float: right;
  flex: 20% 0 0;
}


/* Bagian "bawahf" menjadi dua kolom */


/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 120%;
  width: 100%;
}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 120%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}

/* Styling ikon media sosial */
.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconf {
  /* margin-bottom: 2vh; */
  width: 20px;
  height: 20px;
  margin-right: -10px;
  margin-left: 20px;
}
/* Mengatur bagian "karir" agar tepat di tengah pada "bawahf" */

p{
  margin-bottom: 0px;
}

.tenka{
  margin-top: 20px;
  /* background-color:#3498db ; */
}

.bawahf {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
  background-color: #0D5487;
  color: white;
}

.bawahf .karir {
  display: flex;
  flex: 0 0 90%;
}

.rr{
  display: flex;
}

.row1{
  text-align: left;
}

.row2{
  text-align: right;
}

.col-md-6{
  padding: 0;
}

}
/*-----------------------------------------------------------------*/

@media(min-width:1281px){
  .active-link {
    font-weight: bold;
    color: blue;
    /* Tambahkan gaya lain sesuai kebutuhan Anda */
  }
  .logo{
    width: 100%;
    vertical-align: middle;
    margin-top: -10px;
  }

 
  
  .md_bisnis {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    display: none;
  }
  
  .navbar {
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    border: 0;
    font-weight: Bold;
    font-size: 20px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 2%;
 
    border-radius: 0;
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 50px;
    padding-top: 20px;
  }

  .navbar li a, .navbar .navbar-brand {
    color: #1777bd !important;
    font-size: 20px;
    padding-bottom: 30px;
    /* border: 2px solid red; */
  }
  
  .navbar-nav li a:hover a:active, .navbar-nav li.active a {
    color: #f4511e !important;
    transition: 0.4s ease-in-out;
    /* background-color: #fff !important; */
  }
  
  nav li a.active{
    border-bottom:4px solid #ff7a00;
  }
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }

  /* .btn a.active{

  }
   */
  
  .navbar_m{
    background: url("../assets/images/toko.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .side-menu a{
    text-decoration: none;
    color: black;
  }
  
  .side-menu p{
    margin-bottom: 10%;
  }
  
  /* .bantiban{
    background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .bantiban .navbar li a, .navbar .navbar-brand {
    color: #ffffff !important;
  }
  
  .bantiban .logo{
    width: 100%;
    vertical-align: middle;
    margin-top: -10%;
    opacity: 500%;
    background-blend-mode: hard-light;
  }

  .bg {
    /* margin-top: 3%; */
    height: auto;
    width: 100%;
    background-size:cover;
 
}

.bgon{
  display: grid;
  grid-template-columns: 40% 60%;
}

.bann {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

  .bktitle{
   padding-top: 17%;
   padding-bottom: 17%;
  }
  
  .title{
     margin-left: 20;
     font-size: 30px;
     color: #fff;
     font-weight: bold;
  }
  
  .subtitle{
      margin-top: -2vh;
      font-size: 20px;
      color: #fff;
      font-family: "poppins , sans serif";
  }
  
  .bttn {
    border: 0;
    height: 60px;
    width: 300px;
    border-radius: 10px;
    font-family: 'Poppins';
    color: #0F69AB;
    font-weight: bold;
    /* padding: 12px 60px; */
    text-decoration: none;
    transition: 0.5s ease-in-out;
    background-color: white;
  }
  .bttn:hover{
    background-color:  #dfdfdf; 
  }
  .bttn a {
    text-decoration: none;
    font-size: 20px;
  }
  .yt {
    background-color: #68b9de;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
    display: flex;
    justify-content: center;
    border: #2980b9 2px;
  }

  .bgyt {
    background-color: #68b9de;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 10%;
    left: 0%;
    bottom: 10%;
    right: 0%;
    width: 100%;
    height: 80%;
  }
  
  .btn {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    font-family: "Poppins";
    gap: 5%;
  }
  
  .button {
    background-color: white;
    width: 343px;
    padding: 20px 0;
    border-radius: 20px;
    font-weight: bold;
    font-size: 15px;
  }
  
  .btn-h2bisnis {
    width: auto;
    margin: 0 auto;
    display: block;
  }
  
  /* Styling untuk judul h2 */
  .h2bisnis {
    text-align: left;
  }
  
  /* Styling untuk garis horizontal */
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .button a {
    color: blue;
    text-decoration: none;
    width: auto;
  }
  
  .button:hover,
  .button:focus {
    background-color: #DAEBF8;
  }

  .footer_m {
    bottom: 0;
    width: 100%;
    background-color: #fff;
    margin-top: 65vh;
    position: relative;
}

.btn_bis {
  display: block;
  width: 30px;
  height: 4px;
  background-color: #333;
  margin-bottom: 5px;
  border-radius: 2px;
}

.btn_active{
  background-color: #DAEBF8;

}

/* #myBisnis button.active{
color: #DAEBF8;
} */

.gt {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 45% 50%;
  gap: 5%;
  /* border: 1px solid; */
  /* text-align: right; */
}

.subtitle1{
  text-align: justify;
  /* display: grid; */
}

.subtitle2{
  text-align: justify;
}

.gambar {
  width: 100%;
  height: auto;
}

.pll a {
  text-decoration: none;
  margin-top: 50px;
  font-weight: bold;
  font-size: 20px;
  float: right;
}

.pd {
  background-color: #CFEFFf;
  background-position: center;
  margin: 30px 0;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 5vh 5vw;
}

.hp{
  display: none;
}

.logo-pd{
  width: 135px;
}

.content_pd{
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-pd{
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.btn-primary{
  height: 70px;
  width: 365px;
  border-radius: 10px;
  border: none;
}

.or p{
  padding-left:165px ;
  font-weight: bold;
}

.store {
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  gap: 20px;
  
    /* grid-template-columns: auto auto; */
  }

  .ps,
  .ap {
    cursor: pointer;
  }

/* .img-pd{
  padding: 50px;
} */

.img-1{
  width: 100%;
  height: 40%;
}

/* benefit */

.h2r {
  width: 100%;
  position: relative;
}

.csc{
  font-size: 3vh;
}
/* benefit */

.hr {
  width: 100%;
  color: white;
}

.row{
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

/* CSS untuk membuat footer menjadi responsive */
.footer-container {
  /* padding: 20px; */
  background-color: #f0f0f0;
}

/* Bagian "atasf" menjadi dua kolom kiri dan kanan */
.atasf {
  display: flex;
  gap: 5%;
  flex-direction: row;
  background-color: #0F69AB;
  padding: 2%;
  width: 100%;
}

.atasf .prom{
  float: left;
  flex: 0 0 80%;
  color: white;
}

.atasf .but{
  float: right;
  flex: 20% 0 0;
}

/* Styling tombol Download */
.butdown {
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  height: 120%;
  width: 100%;
}

.butdown a {
  color: #0D5487;
  text-decoration: none;
  font-weight: bold;
}

.butdown:hover{
  background-color: #dfdfdf;
  transition: 0.2s ease-in-out;
}

/* Bagian "bawahf" menjadi dua kolom */
.bawahf {
  align-items: stretch;
  justify-content: space-between;
  padding: 2%;
  padding-left: 0;
  padding-right: 0;
  background-color: #0D5487;
  color: white;
}

/* Styling ikon media sosial */
.iconif {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconf {
  /* margin-bottom: 2vh; */
  width: 20px;
  height: 20px;
  margin-right: -10px;
  margin-left: 20px;
}

/* Mengatur bagian "karir" agar tepat di tengah pada "bawahf" */

p{
  margin-bottom: 0px;
}

.rr{
  display: flex;
}

.row1{
  text-align: left;
 ;
}

.row2{
  text-align: right;
}

.col-md-6{
  padding: 0;
}
}


