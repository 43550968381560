

@media (min-width:1281px){
    .rawr{
    display: grid;
    grid-template-columns: 50%  auto;
    grid-gap: 5%;
    padding: 20px;
  }

.ctc {
    background: url("../../assets/images/kota.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}

.rawr .map_container {
    height: 100%;
    min-height: 375px;
  }
  
.rawr .map_container .map-responsive {
    height: 100%;
}

.tiban{
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size:x-large;
}

  .contactisi{
    display: flex;
    gap: 2%;
  }

  .ctn_ic{
    flex: 0 0 10%;
  }

  .iconc{
    width: 35px;
  }

  .icontac{
    font-size: 15px;
  }

  .map-responsive iframe{
    width: 100%;
    height: 100%;
  }
}

  @media (max-width:1280px) and (min-width:1025px){
    .rawr{
      display: grid;
      grid-template-columns: 50%  auto;
      grid-gap: 5%;
      padding: 20px;
    }
  
  .ctc {
      background: url("../../assets/images/kota.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
  }
  
  .rawr .map_container {
      height: 100%;
      min-height: 375px;
    }
    
  .rawr .map_container .map-responsive {
      height: 100%;
  }
  
  .tiban{
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size:x-large;
  }
  
    .contactisi{
      display: flex;
      gap: 2%;
    }
  
    .ctn_ic{
      flex: 0 0 10%;
    }

    .icontac{
      font-size: 15px;
    }
  
    .iconc{
      width: 35px;
    }
  
    .map-responsive iframe{
      width: 100%;
      height: 100%;
    }

  }

  @media (max-width:1024px) and (min-width:768px){
    .rawr{
      display: grid;
      grid-template-columns:  auto;
      grid-gap: 2%;
      padding: 20px;
    }
  
  .ctc {
      background: url("../../assets/images/kota.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
  }
  
  .rawr .map_container {
      height: 100%;
      min-height: 400px;
    }
    
  .rawr .map_container .map-responsive {
      height: 100%;
  }
  
  .tiban{
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size:x-large;
  }
  
    .contactisi{
      display: flex;
      gap: 2%;
    }
  
    .ctn_ic{
      flex: 0 0 10%;
    }

    .icontac{
      font-size: 20px;
    }
  
    .iconc{
      width: 50px;
    }
  
    .map-responsive iframe{
      width: 100%;
      height: 100%;
    }

  }

   @media (max-width: 767px) and (min-width: 428px){
    .rawr{
      display: grid;
      grid-template-columns:  auto;
      grid-gap: 2%;
      padding: 20px;
    }
  
  .ctc {
      background: url("../../assets/images/kota.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
  }
  
  .rawr .map_container {
      height: 100%;
      min-height: 375px;
    }
    
  .rawr .map_container .map-responsive {
      height: 100%;
  }
  
  .tiban{
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size:x-large;
  }

  .emil{
    font-size: smaller;
  }
  
    .contactisi{
      display: flex;
      gap: 2%;
    }
  
    .ctn_ic{
      flex: 0 0 10%;
    }
  
    .iconc{
      width: 100%;
    }

    .icontac{
      font-size: 15px;
    }
  
    .map-responsive iframe{
      width: 100%;
      height: 100%;
    }
   }

@media (max-width: 427px) and (min-width: 321px){
  .rawr{
    display: grid;
    grid-template-columns:  auto;
    grid-gap: 2%;
    padding: 20px;
  }

.ctc {
    background: url("../../assets/images/kota.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}

.rawr .map_container {
    height: 100%;
    min-height: 300px;
  }
  
.rawr .map_container .map-responsive {
    height: 100%;
}

.tiban{
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size:x-large;
}

.emil{
  font-size: small;
}

  .contactisi{
    display: flex;
    gap: 2%;
  }

  .ctn_ic{
    flex: 0 0 10%;
  }

  .icontac {
    font-size: 10px;
  }

  .iconc{
    width: 100%;
  }

  .map-responsive iframe{
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 320px){
  .rawr{
    display: grid;
    grid-template-columns:  auto;
    grid-gap: 2%;
    padding: 20px;
  }

.ctc {
    background: url("../../assets/images/kota.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}

.rawr .map_container {
    height: 100%;
  } 

.tiban{
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size:x-large;
}

  .contactisi{
    display: flex;
    gap: 2%;
  }

  .ctn_ic{
    flex: 0 0 10%;
  }

  .iconc{
    width: 100%;
  }

  .icontac {
    font-size: 10px;
  }

  .map-responsive iframe{
    width: 100%;
    height: 250px;
  }
}

  