.ben {
  width: auto;
  margin: 0 auto;
}

.hed-ben{
  margin-bottom: -1.7%;
}
  
  
.icon {
    width: 65% ;
    height:50%;
    margin: 0% 17%;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
}


.txticon {
  /* margin: 0 4%; */
  padding: 4%;
  font-size: 20px;
  text-align: center;
 
}


.list {
    display: flex;
    text-decoration: none;
    list-style: none;
    flex-direction: column;
    /* margin: 1% 7%; */
    gap: 35px;
    display: block;
    width: 100%;
}

.inner-container{
  display:flex;
  align-items: center;
  gap: 40px;
  text-decoration: none;
  text-decoration-line: none;
  margin-bottom: 2%;
}

.inner-container a{
  text-decoration: none;
  color: black;
}

.circle {
  background-color: #c6c4c4;
  display:flex;
  border-radius:100%;
  height: 62px;
  border: none;
  width: 60px;
  justify-content:flex-start;
  gap: 20px;
  color: black;
  
}

.circle:hover,
.circle:focus{
  color: white ;
  background-color: #0F69AB;
  
}

.c_active
{
  background-color: #0F69AB;
  color: white;
}



/* .active{
  background-color: #0F69AB;
  color: white;
} */

.text {
  margin:auto;
  font-size: 20px;
  width: 100%;
  text-decoration: none;
  font-weight: bold;
}

.p a{
  color: black;
  text-decoration-line: none;
  text-decoration: none;
  text-transform: none;
}

.box{
  display: grid;
  grid-template-columns: repeat(2,minmax(0,2fr));
  grid-gap: 2%;
}

.md_benefit{
  display: none;
}

@media screen and (max-width:1000px) {
  .md_benefit{
    display: block;
    /* padding: 5%; */
  }

   .box{
    display: none;
   }
 
   .bnn{
    display: grid;
    grid-template-columns: auto;
    text-align: justify;
   }

   .bnn img{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
   }

   .icon {
      width: 100% ;
      height: 100%;
      margin: 0% 0%;
      justify-content: center;
    }
   
}