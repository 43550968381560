
  @media (min-width:1281px){
    a:hover {
      text-decoration: none !important;
    }

    .content_SOR{
      width: 100%;
      padding-top: 20px;
    }
    /* .bantiban {
        background-color: rgba(0, 0, 0, 0.5);
      } */

    .bantiban .navbar-brand{
        padding-top: 25px;
      }

    .bantiban .navbar .container{
        padding-left: 0;
      }
      
  .bantiban .logo{
        padding-left: 0;
    }
  
      .hh {
        color: white;
        font-weight: bold;
        font-size: 20px;
        padding-top: 7vh;
        padding-bottom: 6vh;
      }

    .hh p{
        margin-top:20px;
        margin-left: 15px;
      }

      .container {
        position: relative;
      }
    
    .content {
        width: 820px;
        margin-left: 300px;
        top: 10px;
      }
    
    .side-menu {
        top: 35px;
        width: 250px;
        border-right: 0.5px solid #0F69AB;
        position: absolute;
      }
      .side-menu p{
        cursor: pointer;
      }

      .side-menu .side_active{
        font-weight: bold;
      }

      .bar_mn .side_active{
        font-weight: bold;
      }

      p.title_dewan {
        margin-bottom: 10px;  
     }

      .content_tenka{
        line-height: 30px;
        width: 100%;
      }

      .detail_dewan{
        width: 100%;
        height: auto;
        padding: 20px;
      }

      .img_dewan{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        grid-gap: 20px;
      }

      .cv_dewan{
        display: grid;
        grid-template-columns: 40% 58%;
        outline: 5px solid #ededed;
        border-radius: 10px;
        gap: 10px;
        padding: 5px;
      }

      .name_dewan {
        text-align: center;
        padding-right: 75px;
      }

      .content_SO{
        width: 15vw;
        height: 40vh;
      }

      .img-thumbnail {
        border: none;
        padding-top: 20px;
      }
      
      .content_lpp{
        display: flex;
      }

      .isi_lpp{
        flex: 0 0 100%;
        padding-top: 20px;
      }

      .updown{
        width: 1.5vw;
      }

      .awards{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        gap: 60px;
        padding-top: 20px;
      }
      
      .content_piagam{
        display: flex;
        gap: 20px;
        padding-top: 10px;
      }
      .pdf{
       width: 20px;
       }

       .isi_piagam{
        text-align: right;
       }

       .img_komis{
        display: grid;
        grid-template-columns: auto auto auto;
        text-align: center;
       }

       .name_komite{
        font-weight: bold;
       }
       
       .img_sekre{
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
        text-align: center;
       }

      .content_sekre{
        width: 200px;
        text-align: center;
      }

      .name_sekre{
        font-weight: bold;
        margin-top: 20px;
      }

      .content_lk{
       display:grid;
       grid-template-columns: 100% auto;
      }

      .isi_lk{
        gap: 20%;
      }

      .notification{
        color: black;
        text-align: center;
      }

      .content_lp{
        padding-top: 20px;
        display: grid;
        grid-template-columns: 20% 78% 2%;
      }

      .up{
        width: 20px;
      }

      .tt_rups{
        display: flex;
        gap: 5vw;
      }

      .tt_rups a{
        text-decoration: none;
        color: black;
        font-size: larger;
      }

      .tt_rups .active{
        border-bottom:3px solid blueviolet;
      }

      .contact_us{
        padding: 20px;
      }

      .btn-submit {
        display: flex;
        justify-content: center;
        margin: 30px auto;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        font-family: "Poppins";
        background-color: #0F69AB;
        color: white;
      }

      .text_field {
        padding-top: 10px;
      }

      .field_form {
        border-right: 0.5px solid grey;
        padding-right: 25px;
      }

      textarea {
        max-width: 365px; 
        min-width: 365px;
        max-height: 200px;
        min-height: 50px;
      }

      .e_corp {
        font-size: 14px;
        color: black;
      }

      .form {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
      }

      .heading-kotakkanan {
        font-weight: bold;
        color: orange;
        font-size: 13px;
        font-family: "poppins";
        margin-bottom: 10px;
      }

      .isi-kotakkanan {
        font-size: 15px;
        font-weight: 400;
        font-family: "poppins";
        margin-bottom: 25px;
      }

      .grid-kotakkanan {
        display: grid;
        grid-template-columns: 70% 50%;
        font-weight: 400;
        font-family: "poppins";
      }

      

      .footer_m{
        width: 100%;
        background-color: #fff;
        position: relative;
      }
      .mn_content{
        display: none;
      }

      .content_berita{
        display: grid;
        text-decoration: none;
        color: black;
        grid-template-columns: 50% 50%;
        grid-gap: 25px;
      }

      .content_berita:hover{
        cursor: pointer;
        text-decoration: none;
      }

      .content_berita img{
        width: 100%;
      }

      .content_berita a{
        text-decoration: none;
        float: right;
        padding-top: auto;
      }

      .pagination-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
      }
      
      .pagination ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .pagination ul li {
        display: inline-block;
        margin-right:   0px; /* Jarak antara tombol halaman */
      }
      
      .pagination ul li button {
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        padding: 5px 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 1.5;
        color: #333; /* Warna teks default */
      }
      
      .pagination ul li button.active {
        background-color: #0037ff; /* Warna latar belakang tombol aktif */
        color: #fff; /* Warna teks tombol aktif */
      }
      
      .pagination ul li button:active {
        background-color: #0037ff;
        color: #ffffff;
      }

      .title-news{
        font-size: 25px;
        font-weight: bold;
        color: black;
      }

      .title-news:hover{
        text-decoration: none !important;
      }

      .more{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        padding-top: 20px;
        grid-gap: 20px;
      }

      .img_morenews{
        width: 100%;

      }

      .more_news{
        /* width: 260px; */
        /* padding: 20px; */
        height: 450px;
        /* background: linear-gradient(to bottom, transparent 50%, #0D5487 50%); */
        background-size: 100% 200%;
        background-position: top;
        color: black;
        /* border: 2px solid #ffffff; */
        border-radius: 20px;
        /* transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out; */
      }

      .more_news_title {
        font-weight: 700;
      }

      .more_news_container {
        margin: auto 10px;
      }
  
      /* .more_news:hover{
        background-position: bottom;
        color: white;
        border-color: white;
      } */

      .tanggal_news{
        opacity: 60%;
      }

      .content_more{
        font-size: 100%;
      } 

      .more img{
        width: -webkit-fill-available;
        height: 200px;
        object-fit: cover;
      }

      .d_tel{
        display: block;
        margin-top: -15px;
        
      }

      a.d_tel:hover{
        color: black;
      }

      .isi_berita{
        padding: 25px;
      }

      .bk_berita a{
        text-align: left;
        text-decoration: none;
        font-size: 20px;
        font-weight: Bold;
        padding-bottom: 20px;
       
      }

      .d_news{
        display: grid;
        grid-template-columns: auto;
      }

      .d_news p{
        text-align: justify;
        padding-left: 10%;
        padding-right: 10%;
      }

      .d_news h3{
        text-align: center;
        
      }

      .d_news img{
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      }

      .ctck{
        display: grid;
        grid-template-columns: 30% 70%;
        padding-top: 15px;
      }
  }

  
  @media (max-width: 1280px) and (min-width:1025px){
    a:hover {
      text-decoration: none !important;
    }

    .content_SOR{
      width: 100%;
      padding-top: 20px;
    }
    /* .bantiban {
        background-color: rgba(0, 0, 0, 0.5);
      } */
    .bantiban .logo{
        width: 100%;
        padding-left: 0;
    }

     .bantiban .navbar .container{
        padding-left: 0;
      }
    
      .hh {
        color: white;
        font-weight: bold;
        font-size: 20px;
        padding-top: 7vh;
        padding-bottom: 6vh;

      }

      .hh p{
        margin-top:20px;
        margin-left: 15px;
      }

      .container {
        position: relative;
      }
      
      .content {
        width: 100%;
        margin-left: 20px;
      }
    
      .side-menu {
        margin-top: 20px;
        border-right: 0.5px solid #0F69AB;

      }

      .side-menu .side_active{
        font-weight: bold;
      }

      .content_tenka{
        line-height: 30px;
        width: 100%;
      }

      .detail_dewan{
        width: 100%;
        height: auto;
        padding: 10px;
      }

      p.title_dewan {
        margin-bottom: 10px;  
     }

      .img_dewan{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        grid-gap: 20px;
      }

      .cv_dewan{
        display: grid;
        grid-template-columns: 40% 58%;
        outline: 1px solid black;
        gap: 10px;
      }

      .name_dewan {
        text-align: center;

      }

      .img_dewan{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        grid-gap: 20px;
      }

      .content_SO{
        width: 15vw;
        height: 40vh;
      }

      .img-thumbnail {
       border: none;
       padding-top: 20px;
      }

      .content_lpp{
        display: flex;
        
      }
      .isi_lpp{
        flex: 0 0 100%;
        padding-top: 20px;
      }

      .updown{
        width: 20px;
      }

      .awards{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        gap: 30px;
        padding-top: 20px;
      }

      .content_piagam{
        display: flex;
        gap: 20px;
        padding: 10px;
      }

      .pdf{
       flex: 0 0 100%;
       width: 20px;
       }

      .content_sekre{
        width: 200px;
        text-align: center;
      }
      .content_lk{
       display:grid;
       grid-template-columns: 100% auto;
      }

      .isi_lk{
        gap: 20%;
      }

      .content_lp{
        padding-top: 20px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 5% 90% 2%;
      }

      .up{
        width: 20px;
      }

      .tt_rups{
        display: flex;
        gap: 2vw;
        margin-top: 20px;
      }

      .tt_rups a{
        text-decoration: none;
        color: black;
        font-size: larger;
      }

      .tt_rups .active{
        border-bottom:3px solid orange;
      }

      .contact_us{
        padding: 20px;
      }

      .btn-submit {
        display: flex;
        justify-content: center;
        margin: 30px auto;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        font-family: "Poppins";
        background-color: #0F69AB;
        color: white;
      }

      .text_field {
        padding-top: 10px;
      }

      .field_form {
        border-right: 0.5px solid grey;
        padding-right: 25px;
      }

      textarea {
        max-width: 280px; 
        min-width: 280px;
        max-height: 200px;
        min-height: 50px;
      }

      .e_corp {
        font-size: 13px;
        color: black;
      }

      .form {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
      }

      .style-headingkotak {
        font-weight: 500;
        margin-right: 10px;
      }

      .heading-kotakkanan {
        font-weight: bold;
        color: orange;
        font-size: 13px;
        font-family: "poppins";
        margin-bottom: 10px;
      }

      .isi-kotakkanan {
        font-weight: 400;
        font-family: "poppins";
        margin-bottom: 25px;
      }

      .grid-kotakkanan {
        display: grid;
        grid-template-columns: 70% 50%;
        font-weight: 400;
        font-family: "poppins";
        gap: 10px;
      } 

      .content_berita{
        display: grid;
        text-decoration: none;
        color: black;
        grid-template-columns: 50%  auto;
        grid-gap: 20px;
      }

      .content_berita a{
        text-decoration: none;
        float: right;
        padding-top: auto;
      }


      .content_berita img{
        width: 100%;
      }

      .content_berita img:hover{
        opacity: 60%;
      }

      .pagination-container {
        display: flex;
        justify-content:                                                                                                                                                                                   flex-end;
        margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
      }
      
      .pagination ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .pagination ul li {
        display: inline-block;
        margin-right:10px; /* Jarak antara tombol halaman */
      }
      
      .pagination ul li button {
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        padding: 5px 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 1.5;
        color: #333; /* Warna teks default */
      }
      
      .pagination ul li button.active {
        background-color: #0037ff; /* Warna latar belakang tombol aktif */
        color: #fff; /* Warna teks tombol aktif */
      }
      
      .pagination ul li button:active {
        background-color: #0037ff;
        color: #ffffff;
      }
   
      .title-news{
        font-size: 25px;
        font-weight: bold;
      }

      .more{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        padding-top: 30px; 
      }

      .more_news{
        /* padding: 20px; */
        height: 450px;
        /* background: linear-gradient(to bottom, transparent 50%, #0D5487 50%); */
        background-size: 100% 200%;
        background-position: top;
        color: black;
        border: 2px solid #ffffff;
        border-radius: 20px;
        /* transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out; */
      }

      .more_news_title {
        font-weight: 700;
      }

      .more_news_container {
        margin: auto 10px;
      }
  
      .tanggal_news{
        opacity: 60%;
      }

      .content_more{
        font-size: 100%;
      }

      .more img{
        width: -webkit-fill-available;
        height: 200px;
        object-fit: cover;
      }

      .footer_m{
        width: 100%;
        background-color: #fff;
        position: absolute;
      }

      .mn_content{
        display: none;
      }

      .isi_berita{
        padding: 25px;
      }

      .bk_berita a{
        text-align: left;
        text-decoration: none;
        font-size: 20px;
        font-weight: Bold;
        padding-bottom: 20px;
        color: black;
      }

      .d_news{
        display: grid;
        grid-template-columns: auto;
      }

      .d_news p{
        text-align: justify;
        padding-left: 10%;
        padding-right: 10%;
      }

      .d_news h2{
        text-align: center;
      }

      .d_news img{
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      }

      .ctck{
        display: grid;
        grid-template-columns: 30% 70%;
      }
  }    
  
  @media (max-width: 1024px) and (min-width:768px) {
    .content_SOR{
      width: 100%;
      padding-top: 20px;
    }
    .bantiban {
        background-color: rgba(0, 0, 0, 0.5);
      }
    .bantiban .logo{
          width: 100%;
          margin-left: 0;
    }

    .ctck{
      display: grid;
      grid-template-columns: 17% 83%;
    }

    .bantiban .navbar-brand{
        padding-top: 25px;
      }
    
      .hh {
        color: white;
        font-weight: bold;     
        font-size: 20px;
        padding-top: 7vh;
        padding-bottom: 6vh;
      }

      .isi_lp{
        font-size: 10px;
      }

      .hh p{
        margin-top:20px;
        font-size: 15px;
        padding-left: 0;
      }

      .container {
        position: relative;
      }
    
      .content {
        width: 100%;
        margin-left: 20px;
        top: 10px;
      }

      .side-menu p{
        cursor: pointer;
      }
    
      .side-menu {
        border-right: 0.5px solid #0F69AB;
        margin-left: 3px;
        margin-top: 20px;
      }

      .side-menu .side_active{
        font-weight: bold;
      }

      .detail_dewan{
        width: 100%;
        height: 100%;
        padding: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }

      h3.title_dewan {
        text-align: center;
      }
      p.title_dewan {
        text-align: center;
        margin-bottom: 10px;  
     }

      .img_dewan{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        text-align: center;
      }

      .name_dewan {
        text-align: center;
      }

      p.judul_dewan{
        font-size: 14px;
      }

      .content_SO{
        width: 100%;
        padding: 10px 
      }

      .img-thumbnail {
        border: none;
        padding-top: 20px;
       }
 

      .content_sekre{
        width: 100%;
        text-align: center;
      }

      .content_lpp{
        display: flex;
      }
      .isi_lpp{
        flex: 0 0 100%;
        padding-top: 20px;
      }
      .updown{
        width: 2vw;
      }
      .awards{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
      }
      .content_piagam{
        display: flex;
        gap: 20px;
        padding: 10px;
      }
      .pdf{
       flex: 0 0 100%;
       width: 20px;
       }

      .content_lk{
       display:grid;
       grid-template-columns: 100% auto;
      }

      .isi_lk{
        gap: 20%;
      }

      .content_lp{
        padding-top: 20px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 5% 90% 2%;
      }

      .up{
        width: 20px;
      }

      .tt_rups{
        margin-top: 20px;
        display: flex;
        gap: 2vw;
        font-size: 12px;
      }

      .tt_rups a{
        text-decoration: none;
        color: black;
        font-size: larger;
      }

      .tt_rups .active{
        border-bottom:3px solid orange;
      }
      
      .contact_us{
        padding: 20px;
      }

      .btn-submit {
        display: flex;
        justify-content: center;
        margin: 30px auto;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        font-family: "Poppins";
        background-color: #0F69AB;
        color: white;
      }

      .field_form {
        border-right: 0.5px solid grey;
        padding-right: 25px;
      }

      textarea {
        max-width: 205px; 
        min-width: 205px;
        max-height: 200px;
        min-height: 50px;
      }

      .e_corp {
        font-size: 10px;
        color: black;
      }

      .text_field {
        padding-top: 10px;
      }

      .form {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
      }

      .style-headingkotak {
        font-weight: 500;
        margin-right: 10px;
      }

      .heading-kotakkanan {
        font-weight: bold;
        color: orange;
        font-size: 13px;
        font-family: "poppins";
        margin-bottom: 10px;
      }

      .isi-kotakkanan {
        font-size: 9px;
        font-weight: 400;
        font-family: "poppins";
        margin-bottom: 25px;
      }

      .grid-kotakkanan {
        display: grid;
        grid-template-columns: 70% 50%;
        font-weight: 400;
        font-family: "poppins";
        gap: 9px;
      }
      
      .content_berita{
        padding: 50px;
        text-decoration: none;
        color: black;
        padding-left: 0;
        padding-right: 0;
        display: grid;
        grid-template-columns: 50%  auto;
        grid-gap: 20px;
      }

      .content_berita a{
        text-decoration: none;
        float: right;
        padding-top: 30px;
      }

      .content_berita:hover{
        opacity: 60%;
      }

      .content_berita h2 {
        margin-top: 0px;
      }

      .content_berita p{
        text-align: justify;
        font-size: 100%;
      }

      .content_berita img{
        width: -webkit-fill-available;
        height: 300px;
      }

      .pagination-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
      }
      
      .pagination ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      
      .pagination ul li {
        display: inline-block;
        margin-right: 5px; /* Jarak antara tombol halaman */
      }
      
      .pagination ul li button {
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        padding: 5px 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 1.5;
        color: #333; /* Warna teks default */
      }
      
      .pagination ul li button.active {
        background-color: #0037ff; /* Warna latar belakang tombol aktif */
        color: #fff; /* Warna teks tombol aktif */
      }
      
      .pagination ul li button:active {
        background-color: #0037ff;
        color: #ffffff;
      }

      .title-news{
        font-size: 25px;
        font-weight: bold;
      } 

      .more{
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        grid-gap: 3px;
      }

      .more_news{
        /* padding: 20px; */
        height: 430px;
        /* background: linear-gradient(to bottom, transparent 50%, #0D5487 50%); */
        background-size: 100% 200%;
        background-position: top;
        color: black;
        border: 2px solid #ffffff;
        border-radius: 20px;
        transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out;
      }

      .more_news_title {
        font-weight: 700;
      }
  
      /* .more_news:hover,
      .d_tel:hover{
        background-position: bottom;
        color: white;
        border-color: white;
      } */

      .content_more{
        font-size: 70%;
        text-align:left;
      }

      .tanggal_news{
        opacity: 60%;
      }

      .more img{
        width: -webkit-fill-available;
        height: 130px;
        object-fit: cover;
      }

      .d_tel{
        font-size: 70%;
      }

      .footer_m{
        width: 100%;
        background-color: #fff;
        position: absolute;
      }

      .mn_content{
        display: none;
      }

      .isi_berita{
        padding: 20px;
      }

      .bk_berita a{
        text-align: left;
        text-decoration: none;
        font-size: 20px;
        font-weight: Bold;
        padding-bottom: 20px;
      }

      .d_news{
        display: grid;
        grid-template-columns: auto;
      }

      .d_news p{
        text-align: justify;
        padding-left: 10%;
        padding-right: 10%;
      }

      .d_news h2{
        text-align: center;
      }

      .d_news img{
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
      }
  }        
  @media (max-width: 767px) and (min-width: 428px) {
    .content_SOR{
      width: 100%;
    }

    /* .bantiban {
        background-color: rgba(0, 0, 0, 0.5);
      } */
    .bantiban .logo{
        width: 70%;
        margin-left: 0;
    }
    
      .hh {
        color: white;
        font-weight: bold;
        padding-left:38px;
        font-size: 3vw;
        padding-top: -2vh;
        padding-bottom: 4vh;
      }

      .hh p{
        margin-top:3vh;
      }

      .cc {
        display: none;
      }

      .detail_dewan{
        width: 100%;
        height: 100%;
      }

      h3.title_dewan {
        text-align: center;
      }
      p.title_dewan {
        text-align: center;
        margin-bottom: 10px;  
     }

      .img_dewan{
        display: grid;
        grid-template-columns: 50% 50% ;
        text-align: center;
        grid-gap: 20px;
      }

      .content_SO{
        width: 100%;
        padding: 10%;
      }
      

      .content_sekre{
        width: 100%;
        justify-content: center;
        text-align: center;
      }

      .content_lpp{
        display: flex;
        
      }
      .isi_lpp{
        flex: 0 0 100%;
      }
      .updown{
        width: 5vw;
      }
      .awards{
        display: grid;
        grid-template-columns: auto ;
      }  
    .content_piagam{
      display: flex;
      gap: 20px;
      padding: 10px
    }
    .pdf{
     width: 20px;
     }

     .img_komis{
      display: grid;
      grid-template-columns: auto auto ;
      text-align: center;
     }
     .content_lk{
      display:grid;
      grid-template-columns: 90% auto;
     }

     .isi_lk{
       gap: 20%;
     }

     .content_lp{
      padding-top: 20px;
      display: grid;
      grid-template-columns: 10% 85% 10%;
    }

    .up{
      width: 20px;
    }

    .tt_rups{
      display: flex;
      gap: 5vw;
    }

    .tt_rups a{
      text-decoration: none;
      color: black;
      font-size: larger;
    }

    .tt_rups .active{
      border-bottom:3px solid orange;
    }


    .btn-submit {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      border: none;
      font-family: "Poppins";
      background-color: #0F69AB;
      color: white;
    }

    .text_field {
      padding-top: 10px;
    }

    textarea {
      max-width: 355px; 
      min-width: 355px;
      max-height: 200px;
      min-height: 50px;
    }

    .e_corp {
      font-size: 13px;
      color: black;
    }

    .style-headingkotak {
      font-weight: 500;
      margin-right: 10px;
    }

    .heading-kotakkanan {
      font-weight: bold;
      color: orange;
      font-size: 13px;
      font-family: "poppins";
      margin-bottom: 10px;
    }

    .isi-kotakkanan {
      font-size: 12px;
      font-weight: 400;
      font-family: "poppins";
      margin-bottom: 25px;
    }

    .content_berita{
      padding: 20px;
      display: grid;
      text-decoration: none;
      color: black;
      grid-template-columns:auto;
      grid-gap: 20px;
      text-align: justify;
    }

    .content_berita img{
      width: 100%;
    }

    .d_tal{
      position: relative;
      float: right;
      top: 10px;
      right: 0;
      bottom: 0;
      text-decoration: none;
    }

    .content_berita a{
      text-decoration: none;
      float: right;
      padding-top: 20px;
    }

    .pagination-container {
      display: flex;
      justify-content: center;
      margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
    }
    
    .pagination ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .pagination ul li {
      display: inline-block;
      margin-right: 10px; /* Jarak antara tombol halaman */
    }
    
    .pagination ul li button {
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      padding: 5px 10px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 1.5;
      color: #333; /* Warna teks default */
    }
    
    .pagination ul li button.active {
      background-color: #0037ff; /* Warna latar belakang tombol aktif */
      color: #fff; /* Warna teks tombol aktif */
    }
    
    .pagination ul li button:active {
      background-color: #0037ff;
      color: #ffffff;
    }

    .title-news{
      font-size: 20px;
      font-weight: bold;
    } 

    .more{
      display: grid;
      grid-template-columns: auto ;
      grid-gap: 10px;
    }

    .more_news {
      display: grid;
      grid-gap: 20px;
      padding: 20px;
      background-size: 200% 100%; /* Atur ukuran latar belakang lebih lebar dari lebar elemen */
      background-position: right; /* Atur posisi latar belakang di kiri */
      color: black;
      border: 2px solid #ffffff;
      border-radius: 20px;
      transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out;
      }

      .more_news_title {
        font-weight: 700;
      }

      .more_news:hover {
          background-position: left; /* Ubah posisi latar belakang ke kanan saat dihover */
        
          border-color: white;
      }

    .tanggal_news{
      opacity: 60%;
    }

    .more img{
      width: 100%;
      height: 240px;
    }

    .content_more{
      font-size: 75%;
    }

    .d_tel{
      font-size: 70%;
    }
     
     .footer_m{
      width: 100%;
      background-color: #fff;
      position: absolute;
    }

    .bar_mn .side_active{
      font-weight: bold;
    }

      .mn_content{
		  display: block;
		  padding: 20px;
	}

	.hr_hp{
		  background-color: #0F69AB;
		  height: 2px;
		  margin-top: 0;
	} 

	.bar_mn{
	  	width: 100%;
		  overflow-x: scroll;
		  display: flex;
		  gap: 2%;
	}

	.bar_mn p{
		flex: 0 0 auto;
		border-radius:50%;
		padding: 10px;
		width: auto;
		margin: 0;
	}
  .bar_mn a{
    text-decoration: none;
    color: black;
  }

	.bar_mn::-webkit-scrollbar { 
		display: none;
	}

	.content_mn{
		display: block;
		padding-left: 2vh;
		padding-right: 2vh;
		text-align: justify;
	}
  .footer_m{
    width: 100%;
    background-color: #fff;
    position: relative;
  }

  .isi_berita{
    padding: 5px;
  }

  .bk_berita a{
    text-align: left;
    text-decoration: none;
    font-size: 15px;
    font-weight: Bold;
    padding-bottom: 20px;
  }

  .d_news{
    display: grid;
    grid-template-columns: auto;
  }

  .d_news p{
    text-align: justify;
    padding-left: 10%;
    padding-right: 10%;
  }

  .d_news h2{
    text-align: center;
  }

  .d_news img{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
}

@media (max-width: 427px) and (min-width: 321px) {
  .content_SOR{
    width: 100%;
  }

  .tt_kms {
    font-size: 23px;
  }

  /* .bantiban {
      background-color: rgba(0, 0, 0, 0.5);
    } */
  .bantiban .logo{
      width: 70%;
      margin-left: 0;
  }
  
    .hh {
      color: white;
      font-weight: bold;
      padding-left:35px;
      font-size: 3vw;
      padding-top: -2vh;
      padding-bottom: 4vh;
    }

    .hh p{
      margin-top:3vh;
    }

    .cc {
      display: none;
    }

    .img_dewan{
      display: grid;
      grid-template-columns: 50% 50% ;
      text-align: center;
      grid-gap: 20px;
    }

    .detail_dewan{
      width: 100%;
      height: 100%;
    }

    h3.title_dewan {
      text-align: center;
    }
    p.title_dewan {
      text-align: center;
      margin-bottom: 10px;  
   }

    .img_dewan{
      display: grid;
      grid-template-columns: 50% 50% ;
      text-align: center;
      grid-gap: 20px;
    }

    .content_SO{
      width: 100%;
      padding: 10%;
    }

    .content_sekre{
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    .content_SO{
      width: 100%;
      padding: 10%;
    }
    .content_lpp{
      display: flex;
      
    }
    .isi_lpp{
      flex: 0 0 100%;
    }
    .updown{
      width: 5vw;
    }
    .awards{
      display: grid;
      grid-template-columns: auto ;
    }

    .content_piagam{
      display: flex;
      gap: 20px;
      padding: 10px;
    }

    .pdf{
      width: 20px;
      }

     .img_komis{
      display: grid;
      grid-template-columns: auto auto ;
      text-align: center;
     }
     .img_sekre{
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
      text-align: center;
     }

    .content_sekre{
      width: 100%;
      text-align: center;
    }
    .content_lk{
     display:grid;
     grid-template-columns: 100% auto;
    }

    .isi_lk{
      gap: 20%;
    }

    .content_lp{
      padding-top: 20px;
      display: grid;
      grid-template-columns: 10% 90% 10%;
    }

    .up{
      width: 20px;
    }

    .tt_rups{
      display: flex;
      gap: 5vw;
    }

    .tt_rups a{
      text-decoration: none;
      color: black;
      font-size: larger;
    }

    .btn-submit {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      border: none;
      font-family: "Poppins";
      background-color: #0F69AB;
      color: white;
    }

    .text_field {
      padding-top: 10px;
    }

    textarea {
      max-width: 325px; 
      min-width: 325px;
      max-height: 200px;
      min-height: 50px;
    }

    .e_corp {
      font-size: 13px;
      color: black;
    }

    .style-headingkotak {
      font-weight: 500;
      margin-right: 10px;
    }

    .heading-kotakkanan {
      font-weight: bold;
      color: orange;
      font-size: 13px;
      font-family: "poppins";
      margin-bottom: 10px;
    }

    .isi-kotakkanan {
      font-size: 12px;
      font-weight: 400;
      font-family: "poppins";
      margin-bottom: 25px;
    }

    .content_berita{
      padding: 20px;
      display: grid;
      text-decoration: none;
      color: black;
      grid-template-columns:auto;
      grid-gap: 20px;
      text-align: justify;
    }

    .content_berita img{
      width: 100%;
    }

    .d_tal{
      float: right;
      position: relative;
      top: 20px;
      right: 0;
      bottom: 0;
      text-decoration: none;
    }

    .content_berita a{
      text-decoration: none;
      float: right;
      padding-top: 20px;
    }

    .pagination-container {
      display: flex;
      justify-content: center;
      margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
    }
    
    .pagination ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .pagination ul li {
      display: inline-block;
      margin-right: 10px; /* Jarak antara tombol halaman */
    }
    
    .pagination ul li button {
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      padding: 5px 10px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 1.5;
      color: #333; /* Warna teks default */
    }
    
    .pagination ul li button.active {
      background-color: #0037ff; /* Warna latar belakang tombol aktif */
      color: #fff; /* Warna teks tombol aktif */
    }
    
    .pagination ul li button:active {
      background-color: #0037ff;
      color: #ffffff;
    }

    .more{
      display: grid;
      grid-template-columns: auto ;
    }

    .more_news {
      display: grid;
      grid-gap: 20px;
      padding: 20px;
      /* background: linear-gradient(to right, transparent 50%, #0D5487 50%); */
      background-size: 200% 100%; /* Atur ukuran latar belakang lebih lebar dari lebar elemen */
      background-position: right; /* Atur posisi latar belakang di kiri */
      color:black;
      border: 2px solid #ffffff;
      border-radius: 20px;
      transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out;
      }

      .more_news_title {
        font-weight: 700;
      }

      .more_news:hover {
          background-position: left; /* Ubah posisi latar belakang ke kanan saat dihover */
          color: black;
          border-color: white;
      }

      a.d_tel{
        color:#0D5487;
        font-size: smaller;
        font-weight: bold;
       
       }
       
       a.d_tel:hover{
         color:#0F69AB;
       }


    .tanggal_news{
      opacity: 60%;
    }

    .more img{
      width: 100%;
      height: 230px;
    }

    .bar_mn .side_active{
      font-weight: bold;
    }
     
     .footer_m{
      width: 100%;
      background-color: #fff;
      position: absolute;
    }

    .mn_content{
    display: block;
    padding: 20px;
}

.hr_hp{
    background-color: #0F69AB;
    height: 2px;
    margin-top: 0;
} 

.bar_mn{
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 2%;
}

.bar_mn p{
  flex: 0 0 auto;
  border-radius:50%;
  padding: 10px;
  width: auto;
  margin: 0;
}

.bar_mn::-webkit-scrollbar { 
  display: none;
}

.content_mn{
  display: block;
  padding-left: 2vh;
  padding-right: 2vh;
  text-align: justify;
}
.bar_mn a{
  text-decoration: none;
  color: black;
}
.footer_m{
  width: 100%;
  background-color: #fff;
  position: relative;
}



.bk_berita a{
  text-align: left;
  text-decoration: none;
  font-weight: Bold;
  padding-bottom: 20px;
}

.d_news{
  display: grid;
  grid-template-columns: auto;
}

.d_news p{
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
}

.d_news h2{
  text-align: center;
}

.d_news img{
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
}

@media (max-width: 320px)  {
  .content_SOR{
    width: 100%;
  }

  .tt_kms {
    font-size: 23px;
  }

  /* .bantiban {
      background-color: rgba(0, 0, 0, 0.5);
    } */
  .bantiban .logo{
      width: 70%;
      margin-left: 0;
  }
  
    .hh {
      color: white;
      font-weight: bold;
      padding-left:30px;
      font-size: 3vw;
      padding-top: -2vh;
      padding-bottom: 4vh;
    }

    .hh p{
      margin-top:3vh;
    }

    .cc {
      display: none;
    }

    .img_dewan{
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: center;
      grid-gap: 20px;
    }
    .content_SO{
      width: 100%;
      padding: 10%;
    }
    .content_lpp{
      display: flex;
      
    }
    .isi_lpp{
      flex: 0 0 100%;
    }
    .updown{
      width: 5vw;
    }
    .awards{
      display: grid;
      grid-template-columns: auto ;
    }

    .content_piagam{
      display: flex;
      gap: 10px;
      padding: 10px;
    }

    .pdf{
     flex: 0 0 100%;
     width: 20px;
     }

     .img_komis{
      display: grid;
      grid-template-columns: auto auto ;
      text-align: center;
     }
     
     .img_sekre{
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
      text-align: center;
     }

    .content_sekre{
      width: 100%;
      text-align: center;
    }
    .content_lk{
     display:grid;
     grid-template-columns: 100% auto;
    }

    .isi_lk{
      gap: 20%;
    }

    .content_lp{
      display: grid;
      grid-template-columns: 15% 80% 5%;
      padding-bottom: 10px;
    }

    .up{
      width: 20px;
    }

    .tt_rups{
      display: flex;
      gap: 5vw;
    }

    .tt_rups a{
      text-decoration: none;
      color: black;
      font-size: larger;
    }

    .btn-submit {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      border: none;
      font-family: "Poppins";
      background-color: #0F69AB;
      color: white;
    }

    .text_field {
      padding-top: 10px;
    }

    textarea {
      max-width: 260px; 
      min-width: 260px;
      max-height: 200px;
      min-height: 50px;
    }

    .e_corp {
      font-size: 13px;
      color: black;
    }

    .style-headingkotak {
      font-weight: 500;
      margin-right: 10px;
    }

    .heading-kotakkanan {
      font-weight: bold;
      color: orange;
      font-size: 13px;
      font-family: "poppins";
      margin-bottom: 10px;
    }

    .isi-kotakkanan {
      font-size: 12px;
      font-weight: 400;
      font-family: "poppins";
      margin-bottom: 25px;
    }


    .content_berita{
      padding: 20px;
      display: grid;
      text-decoration: none;
      color: black;
      grid-template-columns:auto;
      grid-gap: 20px;
      text-align: justify;
    }

    .content_berita img{
      width: 100%;
    }

    .d_tal{
      position: relative;
      float: right;
      top: 10px;
      right: 0;
      bottom: 0;
      text-decoration: none;
    }

    .content_berita a{
      text-decoration: none;
      float: right;
      padding-top: 20px;
    }

    .more{
      display: grid;
      grid-template-columns: auto ;
      grid-gap: 10px;
    }

    .pagination-container {
      display: flex;
      justify-content: center;
      margin-top: 20px; /* Sesuaikan dengan jarak yang diinginkan dari konten berita */
    }
    
    .pagination ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .pagination ul li {
      display: inline-block;
      margin-right: 10px; /* Jarak antara tombol halaman */
    }
    
    .pagination ul li button {
      border: 1px solid #ccc;
      background-color: #f9f9f9;
      padding: 5px 10px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 1.5;
      color: #333; /* Warna teks default */
    }
    
    .pagination ul li button.active {
      background-color: #0037ff; /* Warna latar belakang tombol aktif */
      color: #fff; /* Warna teks tombol aktif */
    }
    
    .pagination ul li button:active {
      background-color: #0037ff;
      color: #ffffff;
    }

    .more_news {
      display: grid;
      grid-gap: 20px;
      padding: 20px;      
      background-size: 200% 100%; /* Atur ukuran latar belakang lebih lebar dari lebar elemen */
      background-position: right; /* Atur posisi latar belakang di kiri */
      color: black;
      border: 2px solid #ffffff;
      border-radius: 20px;
      transition: background-position 0.8s ease-in-out, color 0.8s ease-in-out, border-color 0.8s ease-in-out;
      }

      .more_news_title {
        font-weight: 700;
      }

      .more_news:hover {
          background-position: left; /* Ubah posisi latar belakang ke kanan saat dihover */
          color: black;
          border-color: white;
      }


    .tanggal_news{
      opacity: 60%;
    }

    .more img{
      width: 100%;
      height: 150px;
    }

    .mn_content{
    display: block;
    padding: 20px;
    }

.hr_hp{
    background-color: #0F69AB;
    height: 2px;
    margin-top: 0;
  } 

.bar_mn .side_active{
  font-weight: bold;
}

.bar_mn{
    width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 2%;
}

.bar_mn p
{
  flex: 0 0 auto;
  text-decoration: none;
  border-radius:50%;
  padding: 10px;
  width: auto;
  margin: 0;
}

.bar_mn a{
  text-decoration: none;
  color: black;
}
.bar_mn::-webkit-scrollbar { 
  display: none;
}

.content_mn{
  display: block;
  padding-left: 2vh;
  padding-right: 2vh;
  text-align: justify;
}
.footer_m{
  width: 100%;
  background-color: #fff;
  position: relative;
}

.isi_berita{
  padding: 10px;
}

.bk_berita a{
  text-align: left;
  text-decoration: none;
  font-weight: Bold;
  padding-bottom: 20px;
  color: black;
}

.d_news{
  display: grid;
  grid-template-columns: auto;
}

.d_news p{
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
}

.d_news h2{
  text-align: center;
}

.d_news img{
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
}

