.galaxy-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .nucleus {.dot-spinner {
    --uib-size: 40px;
    --uib-speed: .9s;
    --uib-color:#0F69AB;
  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  
    width: 2vw;
    height: 2vw;
    background-color: rgb(4, 0, 255);
    border-radius: 18%;
    position: relative;
    animation: nucleus-rotate 4s linear infinite;
  }
  
  @keyframes nucleus-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .electron {
    width: 0.7vw;
    height: 0.7vw;
    background-color: #0081C9;
    border-radius: 50%;
    position: absolute;
    animation: orbit 4s linear infinite;
  }
  
  .electron-1 { animation-delay: 0.3s; }
  .electron-2 { animation-delay: 0.6s; }
  .electron-3 { animation-delay: 0.9s; }
  .electron-4 { animation-delay: 1.2s; }
  .electron-5 { animation-delay: 1.5s; }
  .electron-6 { animation-delay: 1.8s; }
  .electron-7 { animation-delay: 2.1s; }
  .electron-8 { animation-delay: 2.4s; }
  
  @keyframes orbit {
    0% { transform: rotate(0deg) translateX(30vw) rotate(0deg); }
    100% { transform: rotate(-360deg) translateX(20px) rotate(360deg); }
  }
  
  .neu {
    width: 0.7vw;
    height: 0.7vw;
    background-color: #5BC0F8;
    border-radius: 50%;
    position: absolute;
    animation: orbitt 4s linear infinite;
  }
  
  .neu-1 { animation-delay: 0.3s; }
  .neu-2 { animation-delay: 0.6s; }
  .neu-3 { animation-delay: 0.9s; }
  .neu-4 { animation-delay: 1.2s; }
  .neu-5 { animation-delay: 1.5s; }
  .neu-6 { animation-delay: 1.8s; }
  .neu-7 { animation-delay: 2.1s; }
  .neu-8 { animation-delay: 2.4s; }
  
  @keyframes orbitt {
    0% { transform: rotate(0deg) translateX(30vw) rotate(0deg); }
    100% { transform: rotate(360deg) translateX(20px) rotate(360deg); }
  }
  