.skrng {
  background-color: #ffff;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
  font-size: bold;
  cursor: pointer;
  float: right;
  padding: 10px 20px;
}

.top {
  background-color: #0F69AB;
  color: white;
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
}

.atas {
  display: flex;

}

.bottom {
  background-color: #0D5487;
  color: white;
  padding: 40px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  text-align: left;
}

.bawah {
  display: flex;
}

.sosmed {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.iconf {
  width: 25px;
  height: 25px;
  margin-left: 18px;
}

.outsos{
  display: flex;
}

.copy {
  float: right;
  font-size: 8.5px;
}

@media screen and (max-width:320px) {

  .skrng {
    background-color: #ffff;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 10px;
    font-size: bold;
    cursor: pointer;
    float: right;
    padding: 10px 20px;
  }
  
  .top {
    background-color: #0F69AB;
    color: white;
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  
  .atas {
    display: flex;
  
  }
  
  .bottom {
    background-color: #0D5487;
    color: white;
    padding: 40px;
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    text-align: left;
  }
  
  .bawah {
    display: grid;
    grid-template-columns: 33.3% ;
    gap: 10px;
  }
  
  .sosmed {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .iconf {
    width: 25px;
    height: 25px;
    margin-left: 18px;
  }
  
  .outsos{
    display: flex;
    
  }
  
  .copy {
    float: right;
    font-size: 8.5px;
  }
}